<template>
  <div>
    <div>
      <headerTop></headerTop>
    </div>
    <div>
      <headerMin :searchState="searchState"></headerMin>
    </div>
    <div class="trialParty_content wrap">
      <!-- 索引 -->
      <div class="indexes wrap"> <span @click="$router.push('/')">首页</span> > 上传审方</div>
      <!-- 药师审方的 列表 -->
      <div class="tpc_list" >
        <div @click="GoDetails(v)" v-for="(v, i) in pharmList" :key="i" class="tpcl_details">
          <div class="headerImg">
            <img v-if="v.avatar != null && v.avatar != ''" :src="v.avatar" />
            <img v-else src="@/assets/image/Furtherconsultation_Defaulthead@2x.png" alt />
          </div>
          <div class="txt">
            <p class="p1 one">
              <span>{{ v.realname }}</span> 药师
            </p>
            <p class="p2">上传处方</p>
          </div>
        </div>
      </div>
      <div class="fenye" v-if="showPage">
        <el-pagination
          @current-change="currentChanage"
          background
          layout="prev, pager, next"
          :page-size="16"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <div class="bg1">
      <publicBottom></publicBottom>
    </div>
  </div>
</template>
<script>
import headerTop from "@/components/public/public_headerTop.vue";
import headerMin from "@/components/index/index_header_min";
import publicBottom from "@/components/public/public_bottom.vue";

import { getOnlineList } from "@/request/index";
export default {
  components: {
    headerTop,
    headerMin,
    publicBottom
  },
  props: [],
  data() {
    return {
      searchState: 0,
      search: "",
      pharmList: [], // 药师集合
      pagePharmList: [], // 当前页的药师信息
      showPage: false,
      total: 0
    };
  },
  created() {
    this.start();
  },
  methods: {
    start() {
      this.getPagePharmList(1);
    },
    GoDetails(v) {
      this.$router.push({
        path: "/pharmacist/pharmDetails?id=" + v.id + "&uid=" + v.uid
      });
    },
    GoAllGoods() {
      this.$router.replace({ path: "/allGoods" });
    },
    getPagePharmList(page) {
      getOnlineList({
        cert_type: 3,
        page_size: 16,
        page
      }).then(res => {
        this.pharmList = res.data.data;
        this.total = res.data.total;
        this.showPage = res.data.last_page != 1;
      });
    },
    currentChanage(page) {
      this.getPagePharmList(page);
    }
  }
};
</script>
<style lang="less" scoped>
@import "./shenfang";
</style>